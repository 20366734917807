import { Card, Image, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router';
// import React from 'react'
import React, { useEffect, useState } from "react";
import { hover } from '@testing-library/user-event/dist/hover';
import './mediumCard.css';

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
  };
  return (
      <p className="text " >
          {isReadMore ? text.slice(0, 34) : text}
          <span
              onClick={toggleReadMore}
              className="read-or-hide"
              style={{ color: " #ffa269" }}
          >
              {isReadMore ? "...more" : " less"}
          </span>
      </p>
  );
};


const MediumCard = ({ article }) => { 

let imageUrl= article.content

// Extract the 'src' value from the <img> tag
const imgsrcValue = imageUrl.split('<img')[1].split('src="')[1].split('"')[0];
// console.log(`'src' value: ${imgsrcValue}`);
// console.log(article.title)

    return (
      <Card className="mb-3" style={{ width: "100%", borderRadius:"28px", borderColor: "#ffa269"}}>
        
        <Image src={imgsrcValue} alt={article.title} fluid className='ak-medium-card-image' />
        <Card.Body className='ak-medium-card-body-bg text-light'>
            <Card.Title>
              <span className="fw-bold">
                <ReadMore>
                  {article.title}
                </ReadMore>
              </span>
            </Card.Title>

          <Card.Subtitle className="text-muted">
            {/* {new Date(article.pubDate).toLocaleDateString()} */}
            <a
              href={article.link}
              target='_blank'
              className="text-light link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover "
            > <span className='ak-medium-card-link'>Read Full Blog</span> </a>
          </Card.Subtitle>


        </Card.Body>
      </Card>
      
    );
  };
  
  export default MediumCard;