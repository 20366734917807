import React from 'react'
import './pages.css'
import { Container } from 'react-bootstrap'

import SocialCard from '../componets/SocialCard';
import socialDetails from '../data/socialData';

function Connect() {


 const socialComponents = [];

 socialDetails.forEach((social) => {
  socialComponents.push(
     <SocialCard profilelink={social.profilelink} visiblity={social.visiblity} background={social.background} platform={social.platform} icons={social.icons} key={social.id} secondarybtn={social.secondarybtn} ></SocialCard>
   );
 })



  return (
    <section id='connect'>
    <footer className='ak-connect-bg py-2 pb-3 mt-5'>
      <Container className='text-light '>
        <h2 className='text-center mt-3'>Let's Connect!</h2>
        <h6 className='text-center'>akshaypathickal@gmail.com {'\u00A0\u00A0'}</h6>
          <div className="d-flex justify-content-center ">
            <div className="d-flex justify-content-between py-4">
              <div className='d-flex gap-2 gap-2 gap-sm-4 flex-wrap justify-content-center'>
                {socialComponents}
              </div>
            </div>
          </div>
      </Container>
    </footer>
    </section>
  )
}

export default Connect