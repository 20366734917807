
import foodoyes from "../images/foooes.png"


const experienceData = [
{ 
    id: 1,
    role: "Cloud Engineer",
    company_log: "https://riafy.me/tech/img/RIAFY-wordmark-black.png",
    description: "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer. ural lead-in to additional content. This content is a little bit longer.",
    date_range: "Aug 2022 - Present",
    extra_class: "pt-3 ps-2",
    title_class: "card-title fw-bolder fs-4",
    image_class: "img-fluid rounded-start ak-exp-img-src p-2 pt-4 pt-md-0 h-100 w-100"
},
{ 
    id: 2,
    role: "Frontend Developer",
    company_log: foodoyes,  //"https://th.bing.com/th/id/OIP.78s1UiQs03phdrfj21S-rQHaHa?rs=1&pid=ImgDetMain",
    description: "Collaborated on developing the user-friendly Foodoyes admin dashboard using HTML, CSS, JavaScript, and Bootstrap. Utilized Git and GitHub for version control and collaboration.",
    date_range: "Mar 2022 - Aug 2022",
    extra_class: "",
    title_class: "card-title fw-bolder fs-4",
    image_class: "img-fluid rounded-start ak-exp-img-src p-2 pt-4 pt-md-0 h-100 w-100"

}

]

export default experienceData