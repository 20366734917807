
import './pages.css';
import { Container } from 'react-bootstrap';
import React, { useState } from "react";
import {useRef} from 'react';


const ReadMore = ({ children }) => {

  let length = 454
  let viewMore = "...Read More"
  let viewLess = "..Less"
  const windowWidth = useRef(window.innerWidth);
  const widths = windowWidth.current
  if (widths >= 765) {
    length = 2000
    viewMore = ""
    viewLess = ""
    // console.log(length)
  }

  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
  };
  return (
      <>
          {isReadMore ? text.slice(0, length) : text}
          <span
              onClick={toggleReadMore}
              className="read-or-hide fw-bold ak-about-hover-text-dark"
              style={{ color: " #ffffff" }}
          >
              {isReadMore ? viewMore : viewLess }
          </span>
      </>
  ); 
};





function About() {
  return (
<section className='ak-home-sec-bgc pb-5'>
      <Container className='text-light mb-5'>
        {/* <div className='ak-about'> */}
        <div className="row m-0 ak-about-height">

          <div className='p-0 col-sm-6 col-md-4 bg-light ak-about-b1'>
            <div className="text-center d-flex justify-content-center align-items-center h-100 ">
              <img src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgCiCaPOPS2YDFe820VgABApRxQrS1IIl6UCaHZdv_bFRQB8HMWp_SSQkYI06IVgsivioh1BrcBZxHqm-D6MJYKHwHBKn7-mP2HoqDdBFpcwig7fWCKmmW5wBUxzb1pWHDGagB7D1QEoflNSEe1ir6WiwXKMu4y8Qmhh-rSCDVPdEoxG23tzL5waMDg9b_a/s1600/char.gif" className="ak-about-b1" alt="..."/>
            </div>          
          </div>

          <div className='p-0 col-sm-6 col-md-8 '>
            <div className='ak-about-bg d-flex align-items-center h-100 ak-about-b2'>
              <div className='d-flex flex-column bd-highlight py-3 pt-4 py-sm-5'>
                  <div className='px-3 px-sm-5 bd-highlight'>
                    <h1 className='display-4 fw-bold'>About</h1>
                  </div>
                  <div className='pt-3 px-3 px-sm-5 pt-sm-5 bd-highlight'>
                    <p className='lh-sm fs-6'>

                    <ReadMore>
                                       
I'm a Cloud Engineer at Riafy Technologies, passionate about using Cloud computing and DevOps to empower businesses.  At Riafy, I collaborate with our clients in the aviation, banking, healthcare, and stock market industries to implement innovative solutions.  These solutions include AI chatbots for streamlined customer service and easy booking experiences. Notably, I played a pivotal role in developing India's first generative AI SaaS product on GCP.


Within Riafy, I'm also actively building a comprehensive monitoring system using open-source tools to provide real-time insights and enhance operational efficiency. Additionally, I deploy and manage internal security solutions like VPNs and Bastillion to ensure a robust security posture.


My expertise extends beyond these specific projects. I actively contribute to platforms like LinkedIn, Stack Overflow, and Medium, fostering a collaborative learning environment for the tech community.


Driven by a commitment to excellence in cloud and DevOps, I'm dedicated to continuous improvement and aspire to lead technological innovation in the field. Let's connect and discuss how I can help you leverage the power of cloud and DevOps for your business!


                    </ReadMore>  


                    </p>
                  </div>

              </div>
          </div>
          </div>


        </div>
        {/* </div> */}

      </Container></section>

  )
}

export default About