import React, { useState } from 'react';
import './pages.css';
import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import SkillCard from '../componets/SkillCard';
import SkillList from '../componets/SkillList';
import skillDetails from '../data/skillsData'
import {useRef} from 'react';



function Skills() {

    // VIEW CHANGE CODE 
    let GridView = SkillCard
    let ListView = SkillList

    const [isGridView, setIsGridView] = useState(true);
    const toggleView = () => {
      setIsGridView(!isGridView);
    };
    // VIEW CHANGE CODE 


    // list lengnth
    const [itemsToShow, setItemsToShow] = useState(10);
    const totalItems = skillDetails.length;

    const handleLoadMore = () => {
      // Increase the number of items to display by 15
      setItemsToShow((prevItems) => prevItems + 5);
    };

    const displayedItems = skillDetails.slice(0, itemsToShow);
    let skillListComp =<div className='w-100 h-100'>
                <ul className="fs-sm-4 text-light list-group list-group-flush ak-skill-list-boder w-100 h-100 p-0 m-0 text-center ">
                    {displayedItems.map((item) => (
                      <SkillList title={item.title} key={item.id}></SkillList>
                    ))}
                </ul>
                {itemsToShow < totalItems && (
                  <div className='text-center w-100 pt-2 py-sm-3'>
                    <button onClick={handleLoadMore} className='text-center fw-bold ak-skill-list-more btn-outline p-2' >
                      Show More
                    </button>
                  </div>
                )}
                
                </div>


// skill cards section default

  const skillComponents = [];

  skillDetails.forEach((skill) => {
    skillComponents.push(
      <SkillCard title={skill.title} url={skill.url} key={skill.id}></SkillCard>
    );
  })


  // screen width based, minimum skill card view

    let length = 16
    let viewMore = 8

    const windowWidth = useRef(window.innerWidth);
    const widths = windowWidth.current

    // console.log('width: ',widths );

    if (widths >= 765) {
      length = 18
      viewMore = 12
      // console.log(length)
    }
    // console.log(length)

  // screen width based, minimum skill card view


  const [CardItemsToShow, SetCardItemsToShow] = useState(length);
  const totalItemscard = skillDetails.length;
  
  const handleLoadMorecard = () => {
    // Increase the number of items to display by 15
    SetCardItemsToShow((prevItems) => prevItems + viewMore);
  };
  
  const displayedItemscard = skillDetails.slice(0, CardItemsToShow);
  let skillLists =<>{displayedItemscard.map((carditem) => (
                          <SkillCard title={carditem.title} key={carditem.id} url={carditem.url}></SkillCard>
                        ))}

                    {CardItemsToShow < totalItemscard && (
                        <div className='text-center w-100 pt-4'>
                          <button onClick={handleLoadMorecard} className='text-center fw-bold ak-skill-list-more btn-outline p-2 flash_btn' >
                            Show More
                          </button>
                        </div>
                     )}
                    </>
// skill cards section defaul



  return (
    <section id='skills'>
    <Container className='text-light'>
        <div className='ak-skills-title d-flex justify-content-between align-items-center'>
            <h1 className='mb-4'>Technical Skills and Tools</h1>

            <div className='d-flex flex-column flex-sm-row align-items-end align-items-sm-center justify-content-between mb-4 mb-sm-1 pt-2 pt-sm-0 ak-skill-toggle-div align-center '>
                <label className="form-check-label text-end text-sm-start ak-skill-label-width pb-sm-1 " htmlFor="flexSwitchCheckDefault">
                    {isGridView ? 'Easy View' : 'Card View'}
                  </label>

                <div className="form-check form-switch">
                  <input className="form-check-input ak-skill-toggle-clr" type="checkbox" id="flexSwitchCheckDefault" 
                    onClick={toggleView} />
                </div>
            </div>
        </div>


        <div className="row row-cols-4 row-cols-md-6 py-4 m-0 ak-skill-border px-2 ak-skill-bg ">
            {isGridView ? skillLists : skillListComp }
        </div>

    </Container></section>
  )
}

export default Skills