import React from 'react'
import './SocialCard.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import logo from "../images/docker-logo.png"

function SocialCard(props) {
  return (
    <a className={`btn btn-primary ak-social-btn-border ${props.visiblity}`} title={props.platform} style={{backgroundColor: props.background}} href={props.profilelink}  target="_blank" role="button">
      <i className={props.icons }></i>
      {props.platform=== 'DockerHub' ? <img className="ak-social-card-docker-icon img-fluid" src={logo} alt="docker" /> : <span></span>}
    </a>
  )
}

export default SocialCard