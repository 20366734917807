

const skillData = [

{ 
    id: 1,
    url: 'https://www.pinclipart.com/picdir/big/126-1262879_image-aws-logo-png-clipart.png',
    title: 'AWS'
},
{
    id: 2,
    url: 'https://www.pinclipart.com/picdir/big/76-766456_google-cloud-google-cloud-logo-svg-clipart.png',
    title: 'GCP'
},
{
    id: 3,
    url: 'https://www.pinclipart.com/picdir/big/146-1466131_kubernetes-logo-clipart.png',
    title: 'Kubernetes, currently learning'
},
{
    id: 4,
    url: 'https://www.pinclipart.com/picdir/big/519-5197888_terraform-terraform-icon-png-clipart.png',
    title: 'Terraform'
},
{
    id: 5,
    url: 'https://www.pinclipart.com/picdir/big/231-2312314_docker-has-been-hailed-as-a-cycle-shrinking.png',
    title: 'Docker'
},
{
    id: 6,
    url: 'https://www.pinclipart.com/picdir/big/519-5197384_gitlab-logo-svg-clipart.png',
    title: 'Gitlab'
},
{
    id: 7,
    url: 'https://www.pinclipart.com/picdir/big/28-285621_ubuntu-note-ubuntu-lts-18-04-logo-clipart.png',
    title: 'Ubuntu'
},
{
    id: 8,
    url: 'https://images.ctfassets.net/o7xu9whrs0u9/7Erq6IEoCaJkBtHMhblLzS/9310518537dffc123d3d9059edace8ed/Grafana-logo-2.png',
    title: 'Grafana'
},
{
    id: 9,
    url: 'https://www.pinclipart.com/picdir/big/570-5708783_logo-python-python-logo-clipart.png',
    title: 'Python'
},

{
    id: 10,
    url: 'https://www.pinclipart.com/picdir/big/39-399595_current-projects-github-logo-svg-clipart.png',
    title: 'GitHub'
},
{
    id: 11,
    url: 'https://www.pinclipart.com/picdir/big/135-1358798_react-js-logo-png-clipart.png',
    title: 'ReactJs'
},
{
    id: 12,
    url: 'https://www.pinclipart.com/picdir/big/61-611926_heartbeat-clipart-telemetry-prometheus-monitoring-logo-png-download.png',
    title: 'Prometheus'
},
{
    id: 13,
    url: 'https://www.pinclipart.com/picdir/big/71-717454_auto-ovpn-open-vpn-app-clipart.png',
    title: 'Pritunl VPN'
},
{
    id: 14,
    url: 'https://www.pinclipart.com/picdir/big/211-2111306_google-docs-google-sheets-clipart.png',
    title: 'Google Docs'
},
{
    id: 15,
    url: 'https://www.pinclipart.com/picdir/big/571-5716418_encrypt-https-certificate-encryption-authority-lets-lets-encrypt.png',
    title: 'Certbot SSL'
},
// https://www.pinclipart.com/picdir/big/91-918453_cloudflare-lets-encrypt-secured-by-lets-encrypt-clipart.png
{
    id: 16,
    url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
    title: 'Linux'
},
{
id: 17,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 18,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 19,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 20,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 21,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 22,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 23,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},

{
    id: 24,
    url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
    title: 'Linux'
},
{
id: 25,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 26,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 27,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 28,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 29,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 30,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},
{
id: 31,
url: 'https://www.pinclipart.com/picdir/big/27-278237_o-how-to-set-up-home-vpn-linux.png',
title: 'Linux'
},



    
];


export default skillData;
