import logo from './logo-removebg-preview.png';
import './App.css';
import About from './pages/About';
// import Navbars from './componets/navbar';
import Home from './pages/Home';
import Dummy from './pages/Dummy';
import Skills from './pages/Skills';
import Connect from './pages/Connect';
import Footer from './pages/Footer';
import Certification from './pages/Certification';
import Blogs from './pages/Blogs';
import Experience from './pages/Experience';
import Education from './pages/Education';
import Learning from './pages/Learning';
import { useRef } from 'react';



import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Navbar } from 'react-bootstrap';
import './componets/navaba.css';
import {Image } from 'react-bootstrap';

function App() {

// const resume_link = "https://drive.google.com/file/d/1LFoZpE8TaRTuYgKWFnnwzIjPlgVfn9tq/view?usp=sharing"
const resume_link = "https://drive.google.com/file/d//view?usp=sharing"

const home = useRef();
const about = useRef();
const skills = useRef(); 
const experience = useRef(); 
const certification = useRef(); 
const education = useRef(); 
const learning = useRef(); 
const blogs = useRef(); 
const connect = useRef(); 


const scrollHander = (elmRef) =>{
  console.log(elmRef.current)
  window.scrollTo({ top: elmRef.current.offsetTop, behavior: "smooth"});
}

  return (
    <div className='ak-bgc'>

{/* bvvvvvvv */}

      <Navbar 
              collapseOnSelect expand="lg"
              className="ak-navbar border-opacity-75 text-light" 
              sticky="top"
              data-bs-theme="dark"
          >
            <Container className='text-light'>
              <Navbar.Brand 
                  className='ak-nav-logo-t'
                  onClick={() => scrollHander(home)}
              >
                
                {/* <Image src={logo} alt="akshay logo" fluid className='' /> */}
                <img src={logo} alt="" className='ak-nav-logo'></img>
              
              </Navbar.Brand> 
              <Navbar.Toggle aria-controls="responsive-navbar-nav" /> 
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link className='text-light ak-nav-item' onClick={() => scrollHander(home)}>Home</Nav.Link>
                  <Nav.Link className='text-light ak-nav-item' onClick={() => scrollHander(about)}>About</Nav.Link>
                  <Nav.Link className='text-light ak-nav-item' onClick={() => scrollHander(skills)}>Skills</Nav.Link>
                  <Nav.Link className='text-light ak-nav-item' onClick={() => scrollHander(experience)}>Experience</Nav.Link>
                  <Nav.Link className='text-light ak-nav-item' onClick={() => scrollHander(certification)}>Certification</Nav.Link>
                  <Nav.Link className='text-light ak-nav-item' onClick={() => scrollHander(education)}>Education</Nav.Link>
                  <Nav.Link className='text-light ak-nav-item' onClick={() => scrollHander(learning)}>Learning</Nav.Link>
                  <Nav.Link className='text-light ak-nav-item' onClick={() => scrollHander(blogs)}>Blogs</Nav.Link>
                  <Nav.Link className='text-light ak-nav-item' onClick={() => scrollHander(connect)}>Connect</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link eventKey={2}
                      href={resume_link} 
                      target="_blank" title='Google Drive' 
                      className='border text-dark text-wrap badge ak-btn-nav ak-nav-resume-btn btn-lg px-3 py-2'
                    >
                      Resume 
                      <i className="bi bi-file-earmark-person"></i>
                    </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container> 
          </Navbar> 

{/* bvvvvvvv */}

          <section ref={home} ><Home/></section> 
          <section ref={about} ><About/></section> 
          <section ref={skills} ><Skills/></section> 
          <section ref={experience} ><Experience/></section> 
          <section ref={certification} ><Certification/></section> 
          <section ref={education} ><Education/></section> 
          <section ref={learning} ><Learning/></section> 
          <section ref={blogs} ><Blogs/></section> 
          <section ref={connect} ><Connect/></section> 
      <Footer/>
    </div>
  );
}

export default App;


    // <Home/>
    // <About/>
    // <Skills/>
    // <Experience/>
    // <Certification />
    // <Education/>
    // <Learning/>
    // <Blogs/>
    // <Connect/>