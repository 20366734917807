import linkedinbadge from "../images/linkedinbadge.svg"
import QuestPractitioner from "../images/aws-cloud-quest-cloud-practitioner-image.png"

const badgeData = [

{ 
    id: 1,
    imageLink: "https://developers.google.com/static/profile/badges/community/innovators/cloud/2021_member/badge.svg",
    certName: "Google Cloud Innovator",
    linkto: "https://developers.google.com/profile/u/akakshay",
    tooltips: "Click the image for more details."
},
{ 
    id: 2,
    imageLink: linkedinbadge,
    certName: "Community Top Voice",
    linkto: "https://in.linkedin.com/in/akshayratheesh?trk=profile-badge",
    tooltips: "Click the image for more details.",
    certDate: ""
},
{ 
    id: 3,
    imageLink: QuestPractitioner,
    certName: "AWS Cloud Quest: Cloud Practitioner",
    linkto: "https://explore.skillbuilder.aws/learn/course/11458",
    tooltips: "Click the image for more details." ,
    certDate: ""
},
{ 
    id: 4,
    imageLink: "https://developers.google.com/static/profile/badges/playlists/cloud/cloud-run-serverless-computing/badge.svg",
    certName: "Cloud Run and serverless",
    linkto: "https://developers.google.com/profile/u/akakshay",
    tooltips: "Click the image for more details.",
    certDate: ""
},


]

export default badgeData